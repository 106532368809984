import React from 'react';
import { Layout } from 'antd';

import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { ReactComponent as LogoGr2 } from 'assets/icons/logo.greece2.svg';

import { Tooltip } from 'components';

const { Header } = Layout;

const LayoutHeader = route => {
  return (
    <Header className="app-layout__header">
      <div className="d-flex align-items-center">
        <LogoGr2 className="app-layout__logo-gr" />
        <Tooltip title="Home Page">
          <Logo
            onClick={() =>
              (window.location.href = 'https://gt-gobeyond.gr/')
            }
            className="app-layout__logo"
          />
        </Tooltip>
      </div>
    </Header>
  );
};

export default LayoutHeader;
