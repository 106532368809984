import React from 'react';

const gdprPage = () => {
  return (
    <div>
      <div className="content-title">
        Ενημέρωση αναφορικά με την επεξεργασία δεδομένων προσωπικού χαρακτήρα
      </div>
      <div className="content-paragraph">
        Σε σχέση με την επεξεργασία των δεδομένων προσωπικού χαρακτήρα και
        σύμφωνα με τις υποχρεώσεις που απορρέουν από τον Κανονισμό (ΕΕ) 2016/679
        και τη σχετική ελληνική και ενωσιακή νομοθεσία περί προστασίας δεδομένων
        προσωπικού χαρακτήρα, τα στοιχεία που συμπληρώνονται στην παρούσα στο
        πλαίσιο υποβολής αιτήματος για τη χρηματοδότηση επενδύσεων και
        μεταρρυθμίσεων με πόρους του Ταμείου Ανάκαμψης βάσει του Εθνικού Σχέδιο
        Ανάκαμψης και Ανθεκτικότητας «Ελλάδα 2.0», διαβιβάζονται στην Τράπεζα
        επιλογής σας για να τύχουν επεξεργασίας για το σκοπό αυτό.
      </div>
      <div className="content-paragraph">
        Σε περίπτωση που παρέχετε στην Τράπεζα προσωπικά δεδομένα τρίτων φυσικών
        προσώπων, έχετε προηγουμένως ενημερώσει τα πρόσωπα αυτά με προσήκοντα
        τρόπο ενδεικτικά, και όπου απαιτείται, μέσω παραπομπής στο «Έντυπο
        Ενημέρωσης για την Επεξεργασία Προσωπικών Δεδομένων της Τράπεζας» που
        έχετε επιλέξει και έχετε εξασφαλίσει τη σχετική συγκατάθεσή τους, όπου
        τυχόν αυτή απαιτείται Αναλυτικά, για την επεξεργασία δεδομένων
        προσωπικού χαρακτήρα ανά πιστωτικό ίδρυμα, μπορείτε να ενημερωθείτε μέσω
        των παρακάτω υπερ-συνδέσμων:
      </div>
      <div className="content-paragraph ">
        <ul>
          <li className="mt-2">
            Alpha Bank:{' '}
            <a
              href="https://apps.alpha.gr/GDPR/files/GDPR.pdf"
              target="_blank"
              rel="noreferrer"
            >
              https://apps.alpha.gr/GDPR/files/GDPR.pdf
            </a>
          </li>
          <li className="mt-2">
            Eurobank:{' '}
            <a
              href="https://www.eurobank.gr/el/gdpr-prosopika-dedomena"
              target="_blank"
              rel="noreferrer"
            >
              GDPR - Προσωπικά Δεδομένα
            </a>
          </li>
          <li className="mt-2">
            Εθνική Τράπεζα:{' '}
            <a
              href="https://www.nbg.gr/-/jssmedia/Files/Footer/privacy_statement"
              target="_blank"
              rel="noreferrer"
            >
              https://www.nbg.gr/-/jssmedia/Files/Footer/privacy_statement
            </a>
          </li>
          <li className="mt-2">
            Optima Bank:{' '}
            <a
              href="https://www.optimabank.gr/customer-information/gdpr"
              target="_blank"
              rel="noreferrer"
            >
              Γενικός Κανονισμός Προστασίας Προσωπικών Δεδομένων | Optima bank{' '}
            </a>
          </li>
          <li className="mt-2">
            Παγκρήτια Τράπεζα:{' '}
            <a
              href="https://www.pancretabank.gr/general/gdpr"
              target="_blank"
              rel="noreferrer"
            >
              https://www.pancretabank.gr/general/gdpr
            </a>
          </li>
          <li className="mt-2">
            Τράπεζα Πειραιώς:{' '}
            <a
              href="https://www.piraeusbank.gr/~/media/Gr/Idiwtes/Files/gdpr/PB_Data_Privacy_Notice-Gr-update.pdf"
              rel="noreferrer"
              download
            >
              PB_Data_Privacy_Notice-Gr-update.pdf
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default gdprPage;
