import React from 'react';
import AppRouter from './routes';
import './styles/main.scss';

const App = () => {
  return (
    <div className="app-container">
      <AppRouter />
    </div>
  );
};

export default App;
