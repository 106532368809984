import React from 'react';
import AlertPage from 'components/alert';

const MaintenancePage = () => {
  return (
    <AlertPage
      type={'warning'}
      message={'Η πλατφόρμα είναι υπό Συντήρηση'}
      submessage={'Παρακαλούμε δοκιμάστε αργότερα'}
    />
  );
};

export default MaintenancePage;
