import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';

import { Header } from './components';
const { Content } = Layout;

const AppLayout = () => {
  return (
    <Layout className="app-layout">
      <Header />
      <Content className="app-layout__content">
        <Outlet />
      </Content>
    </Layout>
  );
};

export default AppLayout;
