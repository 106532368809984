import React from 'react';
import { Alert, Row, Col } from 'antd';
import warning from 'assets/icons/warning-purple.svg';

const AlertPage = ({ type, message, submessage }) => {
  return (
    <Row>
      <Col span={24}>
        <Alert
          type={type}
          description={
            <div className="ant-alert-description-message">
              <img alt={type} src={warning} />
              <p>{message}</p>
              <span>{submessage}</span>
            </div>
          }
        ></Alert>
      </Col>
    </Row>
  );
};

export default AlertPage;
