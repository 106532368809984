import { GdprPage } from '../views';
import { MaintenancePage } from '../views';

export const routes = [
  {
    name: 'gdpr-page',
    path: '/static/gdprTerms',
    Element: GdprPage,
    requireAuth: false,
  },
  {
    name: 'maintenance-page',
    path: '/maintenance',
    Element: MaintenancePage,
    requireAuth: false,
  },
];
