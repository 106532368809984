import React from 'react';
import { loginUrl } from 'utils';
import { Layout, Button } from 'antd';

import { HeaderLanding } from 'components/layout/components';
import { ReactComponent as Group25 } from 'assets/icons/group_25.svg';
import { ReactComponent as Login } from 'assets/icons/login_button.svg';
import { ReactComponent as Green_transition } from 'assets/icons/icon-green-transition.svg';
import { ReactComponent as Eksostrefeia } from 'assets/icons/Group_8.svg';
import { ReactComponent as Robotaki } from 'assets/icons/icon-digital.svg';
import { ReactComponent as Light } from 'assets/icons/Frame.svg';
import { ReactComponent as Growth } from 'assets/icons/icon-growth.svg';
import { ReactComponent as GovGreek } from 'assets/icons/gov-greek_1.svg';
import { ReactComponent as Ellada_2_0_dark } from 'assets/icons/ellada_2_0_dark.svg';

const { Content } = Layout;

const LandingPage = () => {
  return (
    <Layout>
      <HeaderLanding className="header-landing" />
      <Content className="taa-banner">
        <div className="taa-content">
          <Group25 className="taa-icon" />
          <div className="taa-paragraph">
            Υποβολή επενδυτικών προτάσεων για χρηματοδότηση από το Ταμείο
            Ανάκαμψης και Ανθεκτικότητας (ΤΑΑ)
          </div>
        </div>
      </Content>
      <Content className="welcome-banner">
        <div className="welcome-title">
          Καλωσήρθατε στην ηλεκτρονική πλατφόρμα υποβολής των αιτήσεων των
          επενδυτικών σχεδίων για δανειακή χρηματοδότηση από το ΤΑΑ.
        </div>
        <div className="welcome-paragraph">
          <p>
            Η νέα πλατφόρμα αποτελεί την <b>κεντρική πύλη εισόδου</b> των
            ιδιωτικών επενδύσεων που θα αξιολογηθούν για να λάβουν την ιδιαίτερα
            ευνοϊκή και πολύ χαμηλότοκη χρηματοδότηση, χάρη στους δανειακούς
            πόρους του ΤΑΑ, oι οποίοι υλοποιούνται στο πλαίσιο του Εθνικού
            Σχεδίου Ανάκαμψης και Ανθεκτικότητας Ελλάδα 2.0 με τη χρηματοδότηση
            της Ευρωπαϊκής Ένωσης – NextGenerationEU.
          </p>
          <p>
            Απευθύνεται προς όλες τις ελληνικές επιχειρήσεις, ανεξαρτήτως
            μεγέθους και νομικής μορφής, οι οποίες έχουν τη δυνατότητα να λάβουν
            για την επένδυσή τους έως το 50% του κόστους από πόρους του ΤΑΑ, με
            σταθερό επιτόκιο που σήμερα είναι 0,35%, όπως ορίζεται στην
            Υπουργική Απόφαση.
          </p>
          <p>
            Οι επενδύσεις αυτές θα οδηγήσουν στη διττή πράσινη και ψηφιακή
            μετάβαση, δεδομένου ότι το{' '}
            <b>37% των δαπανών τους θα αφορούν το κλίμα</b> και το{' '}
            <b>20% την προώθηση της ψηφιακής μετάβασης</b>.
          </p>
          <p>
            Σημειώνεται ότι το τελικό ύψος της χρηματοδότησης του επενδυτικού
            σχεδίου, από τα δάνεια του ΤΑΑ, υπολογίζεται σύμφωνα με την ύπαρξη
            προϋπολογισμού επιλέξιμων επενδυτικών δαπανών στους πέντε πυλώνες
            του δανειακού σκέλους του «Ελλάδα 2.0», καθώς και με την κάλυψη
            συγκεκριμένων κριτηρίων ανά πυλώνα.
          </p>
        </div>
        <div>
          <Button
            className="login-button"
            onClick={() => (window.location = loginUrl)}
          >
            <span>Εισοδος στην εφαρμογή</span>
            <Login className="login-button-icon" />
          </Button>
        </div>
      </Content>
      <Content className="pylwnes">
        <div className="pylwnes-title">
          Οι Πυλώνες του Ταμείου Ανάκαμψης και Ανθεκτικότητας
        </div>
        <div className="pylwnes-icons">
          <div className="first-row">
            <div className="contents-icons">
              <div>
                <Green_transition />
              </div>
              <div>Πράσινη Ανάπτυξη</div>
            </div>
            <div className="contents-icons">
              <div>
                <Robotaki />
              </div>
              <div>Ψηφιακός Μετασχηματισμός</div>
            </div>
            <div className="contents-icons">
              <div>
                <Eksostrefeia />
              </div>
              <div>Εξωστρέφεια</div>
            </div>
          </div>
          <div className="second-row">
            <div className="contents-icons">
              <div>
                <Growth />
              </div>
              <div>
                Ανάπτυξη Οικονομιών Κλίμακας μέσω Συνεργασιών, Εξαγορών &
                Συγχωνεύσεων
              </div>
            </div>
            <div className="contents-icons">
              <div>
                <Light />
              </div>
              <div>Kαινοτομία - Έρευνα & Ανάπτυξη</div>
            </div>
          </div>
        </div>
      </Content>
      <Content className="landing-page-footer">
        <Ellada_2_0_dark
          className="footer-logo"
          onClick={() => (window.location.href = 'https://greece20.gov.gr/')}
        />
        <p className="foooter-paragraph">
          Η δράση υλοποιείται στο πλαίσιο του Εθνικού Σχεδίου Ανάκαμψης και
          Ανθεκτικότητας Ελλάδα 2.0 <br /> με τη χρηματοδότηση της Ευρωπαικής
          Ένωσης – NextGenerationEU
        </p>
        <p>
          Copyright © 2022 - Υλοποίηση από την{' '}
          <a href="https://www.hba.gr/" target="_blank">
            Ελληνική Ένωση Τραπεζών
          </a>
        </p>
      </Content>
    </Layout>
  );
};

export default LandingPage;
