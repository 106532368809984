import React from 'react';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';

const CustomTooltip = ({ placement = 'topLeft', children, ...rest }) => {
  return (
    <>
      <Tooltip placement={placement} {...rest}>
        {children}
      </Tooltip>
    </>
  );
};

CustomTooltip.propTypes = {
  placement: PropTypes.string,
};

export default CustomTooltip;
