import React from 'react';
import { Layout } from 'antd';

import { ReactComponent as EllinikiDimokratia } from 'assets/icons/elliniki_dimokratia_logo.svg';
import { ReactComponent as Ellada_2_0 } from 'assets/icons/ellada_2_0.svg';

const { Header } = Layout;

const HeaderLanding = () => {
  return (
    <Header className="app-layout__header_landing">
      <div className="header-left">
        <EllinikiDimokratia className="app-layout__logo-gr-democracy" />
      </div>
      <div className="header-right">
        <Ellada_2_0
          className="app-layout__logo-gr-2-0"
          onClick={() => (window.location.href = 'https://greece20.gov.gr/')}
        />
      </div>
    </Header>
  );
};

export default HeaderLanding;
